import { ContentDelivery } from '@episerver/spa-core';
import { LinksListBlock } from './LinkListBlock';
import { HeaderProps } from '../Header/Header';
import React from 'react';
import { Link } from 'react-router-dom';

type MenuItemLinkModel = {
    links: ContentDelivery.LinkListProperty;
    linksHeading: string;
};

type MenuItemProps = HeaderProps & {
    childPageVisited: boolean;
    menuItemHeading: string;
    showDropDown: boolean;
    clickOnMenuItem?: () => void;
    menuItemRef?: React.MutableRefObject<any>;
    listProp?: MenuItemLinkModel[];
    clickedOnLink?: any;
    selected?: boolean;
    href?: string;
    id?: string;
    onfocus?: any;
};

export const MenuItem: React.FunctionComponent<MenuItemProps> = (props) => {
    const links = props.listProp?.map((menuItemModel, index) => {
        if (menuItemModel?.linksHeading === '' || menuItemModel?.links?.value?.length === 0) {
            return null;
        }
        return (
            <LinksListBlock
                key={`${index}-${menuItemModel.linksHeading}`}
                isMobile={false}
                isMenuOpen={(isMenuOpen: boolean) => props.clickedOnLink(isMenuOpen)}
                {...props}
                header={menuItemModel.linksHeading}
                listProp={menuItemModel.links}
                className="col-4 sub-menu-column"
            />
        );
    });

    if (props.href === '' && (props.menuItemHeading === '' || links.length < 1)) {
        return null;
    }

    const isAnchorEl = props.href !== '';

    return (
        <li className="me-1" role="none">
            {isAnchorEl ? (
                <Link
                    role="menuitem"
                    aria-haspopup="true"
                    aria-expanded="false"
                    to={props.href}
                    onFocus={() => props.onfocus()}
                    tabIndex={-1}
                    id={props.id !== '' ? props.id : null}
                    className={`header-menu-item card-yellow-focus  ${props.selected ? 'selected' : ''}  ${
                        props.childPageVisited ? ' child-page-visited' : ''
                    }`}
                >
                    <span>{props.menuItemHeading}</span>
                </Link>
            ) : (
                <button
                    role="menuitem"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onFocus={() => props.onfocus()}
                    tabIndex={-1}
                    id={props.id !== '' ? props.id : null}
                    className={`header-menu-item card-yellow-focus  ${props.selected ? 'selected' : ''}  ${
                        props.childPageVisited ? ' child-page-visited' : ''
                    }`}
                    onClick={() => props.clickOnMenuItem()}
                >
                    <span>{props.menuItemHeading}</span>
                </button>
            )}
            {!isAnchorEl && (
                <div
                    ref={props.menuItemRef}
                    className={`nav-menu-group py-5 d-none ${
                        props.showDropDown ? 'd-lg-flex sub-menu-min-height' : ''
                    }`}
                >
                    {links}
                </div>
            )}
        </li>
    );
};

MenuItem.defaultProps = {
    menuItemHeading: '',
    menuItemRef: null,
    selected: false,
    listProp: null,
    href: '',
    id: '',
};

export default MenuItem;
