import React, { useEffect, useState, useRef } from 'react';

//Import Episerver Libs
import { useEpiserver, State } from '@episerver/spa-core';

//Import App
import {
    Typography,
    Icon,
    SearchIcon,
    MenuIcon,
    CloseIcon,
    ArrowLeftIcon,
    LanguageIcon,
    ArrowRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
} from '@norges-domstoler/dds-components';
import { getLocalizationText, General, Header } from '../../../../utils/getLocalizationText';
import { samegiella_language_tag_short } from '../../../../Models/Constants/LanguageTypes';
import LayoutSettings from '../../../../Models/Content/LayoutSettingsData';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { LinksListBlock } from '../MenuItem/LinkListBlock';
import { SearchBoxHeader } from './SearchBoxHeader';
import { connect } from 'react-redux';
import store from '../../../../store';
import './Header.scss';
import { Link } from 'react-router-dom';

export type MobileProps = Partial<State.CmsState> & {
    dispatch?: (action: State.CmsStateAction) => void;
    setIsOpenLanguageSelector: any;
    isMenuOpen?: any;
    path?: string;
    searchPath?: string;
    language: string;
    settings: LayoutSettings;
    screenwidth: number;
    languageNames: { [id: string]: string };
    useLanguageSelector: boolean;
};

export const DefaultHeaderMobile: React.FunctionComponent<MobileProps> = (props) => {
    const mobile_max_width = 480;

    const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false);
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    const [subMenuType, setSubMenuType] = useState<string[]>([]);
    const [subMenuTypeSelected, setSubMenuTypeSelected] = useState<string>('');
    const [isOpenLanguage, setIsOpenLanguage] = useState<boolean>(false);

    const searchFieldRef = useRef();

    const anchorClickHandler = (menu: string) => {
        subMenuTypeSelected === menu ? setSubMenuTypeSelected('') : setSubMenuTypeSelected(menu);
        subMenuType.includes(menu)
            ? setSubMenuType(subMenuType.filter((x) => x !== menu))
            : setSubMenuType([...subMenuType, menu]);
    };

    const handleSubMenuLinkClick = (hasClickedOnLink: boolean) => {
        setIsOpenMenu(!hasClickedOnLink);
        store.dispatch({ type: 'isMenuOpen', payload: !hasClickedOnLink });
    };

    const toggleSearchMenuMobile = () => {
        props.isMenuOpen(!isOpenSearch);
        const prevState = isOpenSearch;
        setIsOpenSearch(!prevState);
        store.dispatch({ type: 'isMenuOpen', payload: !prevState });
        setIsOpenLanguage(false);
    };

    const toggleMenuMobile = () => {
        const prevState = isOpenMenu;
        setIsOpenMenu(!prevState);
        store.dispatch({ type: 'isMenuOpen', payload: !prevState });
        setIsOpenLanguage(false);
        setIsOpenSearch(false);
    };

    const toggleLanguageMenu = () => {
        setIsOpenLanguage((prevState) => !prevState);
    };

    useEffect(() => {
        if (isOpenMenu) {
            setSubMenuType([]);
            setSubMenuTypeSelected('');
        }
    }, [isOpenMenu]);

    return (
        <div className="header-mobile">
            <div
                className={
                    'header-top-mobile ' + (props.screenwidth > mobile_max_width ? 'two-fractions' : 'mobile-section')
                }
            >
                {/* Top menu mobile */}
                <div className="image-mobile">
                    <div className="col-12 col-lg header-image">
                        {
                            <Link
                                className="image-card-focus"
                                onClick={() => {
                                    setIsOpenMenu(false);
                                    setIsOpenSearch(false);
                                    store.dispatch({ type: 'isMenuOpen', payload: false });
                                }}
                                to="/"
                            >
                                <img
                                    alt={getLocalizationText(props.language, [Header, 'Logo'])}
                                    width="91"
                                    height="48"
                                    className={
                                        'mobile-logo ' +
                                        (props.language == samegiella_language_tag_short ? 'samisk' : '')
                                    }
                                    src={'/Static/logo/horisontallogo_mobile_' + props.language + '.svg'}
                                />
                            </Link>
                        }
                    </div>
                </div>
                {props.screenwidth <= mobile_max_width && (
                    <>
                        <div className="mobile-section-menu-search">
                            <button
                                style={{ visibility: isOpenMenu ? 'hidden' : 'visible' }}
                                className="card-yellow-focus-mobile header-button-mobile search-box-header-button-mobile px-0"
                                aria-expanded={isOpenSearch}
                                aria-controls="header-mobile-search-box-wrapper"
                                onClick={() => toggleSearchMenuMobile()}
                            >
                                <Typography typographyType="bodySans02" className="header-button-text pe-2" as="span">
                                    {isOpenSearch
                                        ? getLocalizationText(props.language, [General, 'Close'])
                                        : getLocalizationText(props.language, [General, 'Search'])}
                                </Typography>
                                {isOpenSearch ? (
                                    <Icon className="language-icon" icon={CloseIcon} />
                                ) : (
                                    <Icon className="search-box-icon" icon={SearchIcon} iconSize={'small'} />
                                )}
                            </button>
                            <button
                                className="card-yellow-focus-mobile header-button-mobile menu-section-mobile"
                                aria-label={getLocalizationText(props.language, [Header, 'MainMenu'])}
                                aria-expanded={isOpenMenu}
                                aria-controls="mobile-header-menu"
                                onClick={() => toggleMenuMobile()}
                            >
                                <Typography typographyType="bodySans02" className="header-button-text pe-2" as="span">
                                    {isOpenMenu
                                        ? getLocalizationText(props.language, [General, 'Close'])
                                        : getLocalizationText(props.language, [Header, 'MainMenu'])}
                                </Typography>
                                {isOpenMenu ? (
                                    <Icon className="language-icon close-open-icon" icon={CloseIcon} />
                                ) : (
                                    <Icon className="" icon={MenuIcon} />
                                )}
                            </button>
                        </div>
                    </>
                )}
                {props.screenwidth > mobile_max_width && (
                    <div className="mobile-search-and-menu">
                        <button
                            style={{ visibility: isOpenMenu ? 'hidden' : 'visible' }}
                            className="card-yellow-focus-mobile header-button-mobile search-box-header-button-mobile"
                            aria-label={getLocalizationText(props.language, [General, 'Search'])}
                            onClick={() => toggleSearchMenuMobile()}
                        >
                            <Typography typographyType="bodySans02" className="header-button-text pe-1" as="span">
                                {isOpenSearch
                                    ? getLocalizationText(props.language, [General, 'Close'])
                                    : getLocalizationText(props.language, [General, 'Search'])}
                            </Typography>
                            {isOpenSearch ? (
                                <Icon className="language-icon" icon={CloseIcon} />
                            ) : (
                                <Icon className="search-box-icon" icon={SearchIcon} />
                            )}
                        </button>
                        <button
                            className="card-yellow-focus-mobile header-button-mobile menu-section-mobile pe-0"
                            aria-label={getLocalizationText(props.language, [Header, 'MainMenu'])}
                            onClick={() => toggleMenuMobile()}
                        >
                            <Typography typographyType="bodySans02" className="header-button-text pe-2" as="span">
                                {isOpenMenu
                                    ? getLocalizationText(props.language, [General, 'Close'])
                                    : getLocalizationText(props.language, [Header, 'MainMenu'])}
                            </Typography>
                            {isOpenMenu ? (
                                <Icon className="language-icon close-open-icon" icon={CloseIcon} />
                            ) : (
                                <Icon className="" icon={MenuIcon} />
                            )}
                        </button>
                    </div>
                )}
            </div>

            {/* dropdown from top menu mobile */}
            {(isOpenMenu || isOpenSearch) && (
                <div
                    className={
                        'dropdown-menu-mobile' + (isOpenLanguage || isOpenSearch ? ' show-languages ' : ' show-links ')
                    }
                >
                    {/* Search dropdown mobile */}
                    {isOpenSearch && (
                        <div
                            id="header-mobile-search-box-wrapper"
                            className={
                                'search-box-header-row dropdown-menu search-dropdown-mobile' +
                                (isOpenLanguage || isOpenSearch ? ' show-languages ' : ' show-links ')
                            }
                        >
                            <div className="py-5">
                                <div className="container page-container py-5">
                                    <div className="row">
                                        <SearchBoxHeader
                                            searchFieldRef={searchFieldRef}
                                            isOpenSearch={isOpenSearch}
                                            isMobile={true}
                                            setIsOpenSearch={(isOpenSearch: boolean) => setIsOpenSearch(isOpenSearch)}
                                            searchPath={props.searchPath}
                                            language={props.language}
                                            path={props.path}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Links and language dropdown mobile */}
                    {isOpenMenu && (
                        <nav
                            id="mobile-header-menu"
                            aria-label={getLocalizationText(props.language, [Header, 'MainMenu'])}
                            className={
                                'links-language-dropdown-mobile dropdown-menu' +
                                (isOpenLanguage || isOpenSearch ? ' show-languages ' : ' show-links ')
                            }
                        >
                            {isOpenLanguage && (
                                <div className="language-section-mobile">
                                    <div className="language-section-container left">
                                        <button
                                            className="language-section-open cursor-name-button card-yellow-focus-mobile "
                                            onClick={() => toggleLanguageMenu()}
                                        >
                                            <Icon
                                                color="rgba(53, 71, 84, 1)"
                                                className="language-icon-mobile open"
                                                icon={ArrowLeftIcon}
                                                iconSize="small"
                                            />
                                            <Typography
                                                typographyType="bodySans02"
                                                className="mx-2 language-text-mobile open"
                                            >
                                                {getLocalizationText(props.language, [General, 'Back'])}
                                            </Typography>
                                        </button>
                                    </div>

                                    <LanguageSelector
                                        useEnglish={props?.settings?.useEnglish?.value}
                                        useSamisk={props?.settings?.useSamisk?.value}
                                        layoutSettingsLanguage={props.settings?.language?.name}
                                        isMobile={true}
                                        useLanguageSelector={props.useLanguageSelector}
                                        isOpenLanguageSelector={isOpenLanguage}
                                        setIsOpenLanguageSelector={(isOpenLanguageSelector: boolean) => {
                                            setIsOpenMenu(false);
                                            setIsOpenLanguage(false);
                                            setIsOpenSearch(false);
                                            store.dispatch({ type: 'isMenuOpen', payload: isOpenLanguageSelector });
                                        }}
                                        isMenuOpen={(isMenuOpen: boolean) => props.isMenuOpen(isMenuOpen)}
                                        languageNames={props.languageNames}
                                        path={props.path}
                                    />
                                </div>
                            )}

                            {/* Link menu */}
                            {!isOpenLanguage && (
                                <>
                                    {props.useLanguageSelector && (
                                        <div className="language-section-container right">
                                            <button
                                                className="language-section cursor-name-button card-yellow-focus-mobile "
                                                onClick={() => toggleLanguageMenu()}
                                            >
                                                <Typography
                                                    typographyType="bodySans02"
                                                    className="language-text-mobile"
                                                >
                                                    {getLocalizationText(props.language, [Header, 'Language'])}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    className="language-icon-mobile"
                                                    icon={LanguageIcon}
                                                    iconSize="medium"
                                                />
                                            </button>
                                        </div>
                                    )}

                                    <div
                                        className={
                                            'grid-horizontal-mobile' + (props.useLanguageSelector ? '' : ' mt-5')
                                        }
                                    ></div>
                                    {props?.settings?.findCourtFromHeader?.value ? (
                                        <Link
                                            className="main-menu-item-mobile card-yellow-focus-mobile"
                                            to={props.settings.findCourtFromHeader?.value?.href}
                                            onClick={() => {
                                                anchorClickHandler('');
                                                setIsOpenMenu(false);
                                                store.dispatch({ type: 'isMenuOpen', payload: false });
                                            }}
                                        >
                                            <Typography typographyType="bodySans02" className="">
                                                {props.settings.findCourtFromHeader?.value?.text}
                                            </Typography>
                                            <Icon color="rgba(53, 71, 84, 1)" icon={ArrowRightIcon} iconSize="small" />
                                        </Link>
                                    ) : (
                                        <div></div>
                                    )}

                                    {(props.settings?.beforeMeetingTheCourtsLinks?.value?.length > 0 ||
                                        props.settings?.summonedForTrialLinks?.value?.length > 0 ||
                                        props.settings?.publicAndInsightsLinks?.value?.length > 0) && (
                                        <>
                                            <div className="grid-horizontal-mobile"></div>
                                            <a
                                                className={`main-menu-item-mobile card-yellow-focus-mobile ${
                                                    subMenuTypeSelected === 'inCourt' ? 'selected' : ''
                                                }`}
                                                href="#incourt"
                                                onClick={() => anchorClickHandler('inCourt')}
                                            >
                                                <Typography typographyType="bodySans02" as="div" id="inCourt">
                                                    {props.settings?.inCourtHeading?.value}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    icon={
                                                        subMenuType.includes('inCourt')
                                                            ? ChevronUpIcon
                                                            : ChevronDownIcon
                                                    }
                                                    iconSize="small"
                                                />
                                            </a>
                                            {subMenuType.includes('inCourt') && (
                                                <div className={`submenus-mobile pt-5`}>
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.beforeMeetingTheCourtsHeading.value}
                                                        listProp={props.settings.beforeMeetingTheCourtsLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.summonedForTrialHeading.value}
                                                        listProp={props.settings.summonedForTrialLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.publicAndInsightsHeading.value}
                                                        listProp={props.settings.publicAndInsightsLinks}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {(props.settings?.themeLinks?.value?.length > 0 ||
                                        props.settings?.servicesLinks?.value?.length > 0 ||
                                        props.settings?.logInLinks?.value?.length > 0) && (
                                        <>
                                            <div className="grid-horizontal-mobile"></div>
                                            <a
                                                className={`main-menu-item-mobile card-yellow-focus-mobile ${
                                                    subMenuTypeSelected === 'servicesAndForms' ? 'selected' : ''
                                                }`}
                                                href="#services"
                                                onClick={() => anchorClickHandler('servicesAndForms')}
                                            >
                                                <Typography typographyType="bodySans02" as="div" id="servicesAndForms">
                                                    {props.settings?.servicesAndFormsHeading?.value}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    icon={
                                                        subMenuType.includes('servicesAndForms')
                                                            ? ChevronUpIcon
                                                            : ChevronDownIcon
                                                    }
                                                    iconSize="small"
                                                />
                                            </a>
                                            {subMenuType.includes('servicesAndForms') && (
                                                <div className={`submenus-mobile pt-5`}>
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.themeHeading.value}
                                                        listProp={props.settings.themeLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.servicesHeading.value}
                                                        listProp={props.settings.servicesLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.logInHeading.value}
                                                        listProp={props.settings.logInLinks}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {(props.settings?.courtsInNorwayLinks?.value?.length > 0 ||
                                        props.settings?.judgesLinks?.value?.length > 0 ||
                                        props.settings?.aboutUsLinks?.value?.length > 0) && (
                                        <>
                                            <div className="grid-horizontal-mobile"></div>
                                            <a
                                                className={`main-menu-item-mobile card-yellow-focus-mobile ${
                                                    subMenuTypeSelected === 'aboutUs' ? 'selected' : ''
                                                }`}
                                                href="#aboutus"
                                                onClick={() => anchorClickHandler('aboutUs')}
                                            >
                                                <Typography typographyType="bodySans02" as="div" id="aboutUs">
                                                    {props.settings.aboutHeading.value}
                                                </Typography>
                                                <Icon
                                                    color="rgba(53, 71, 84, 1)"
                                                    icon={
                                                        subMenuType.includes('aboutUs')
                                                            ? ChevronUpIcon
                                                            : ChevronDownIcon
                                                    }
                                                    iconSize="small"
                                                />
                                            </a>
                                            {subMenuType.includes('aboutUs') && (
                                                <div className={`submenus-mobile pt-5`}>
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.courtsInNorwayHeading.value}
                                                        listProp={props.settings.courtsInNorwayLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.judgesHeading.value}
                                                        listProp={props.settings.judgesLinks}
                                                        className="mb-5"
                                                    />
                                                    <LinksListBlock
                                                        isMobile={true}
                                                        isMenuOpenMobile={(isMenuOpenMobile: boolean) =>
                                                            handleSubMenuLinkClick(isMenuOpenMobile)
                                                        }
                                                        {...props}
                                                        header={props.settings.aboutUsHeading.value}
                                                        listProp={props.settings.aboutUsLinks}
                                                        className="mb-5"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div className="grid-horizontal-mobile mobile-bottom"></div>
                                    <div className="mobile-bottom"></div>
                                </>
                            )}
                        </nav>
                    )}
                </div>
            )}
        </div>
    );
};

export const ConnectedLanguageSelector = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(
    DefaultHeaderMobile
);

export const HeaderMobile: React.FunctionComponent<MobileProps> = (props) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <HeaderMobile {...props} />;
    return <ConnectedLanguageSelector {...props} />;
};

export default HeaderMobile;
