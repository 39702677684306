import { ContentDelivery, useEpiserver } from '@episerver/spa-core';
import { Link } from 'react-router-dom';
import { Typography, Icon, ArrowRightIcon, OpenExternalIcon } from '@norges-domstoler/dds-components';
import React from 'react';

type LinkListLinkProps = {
    isMobile?: boolean;
    isMenuOpen?: (isOpen: boolean) => void;
    isMenuOpenMobile?: (isOpen: boolean) => void;
    link: ContentDelivery.LinkProperty;
};

export const LinkListLink = ({ link, isMobile, isMenuOpenMobile, isMenuOpen }: LinkListLinkProps) => {
    const url = new URL(link.href);
    const ctx = useEpiserver();
    let offDomain: boolean = true;
    try {
        if (window.location.protocol !== url.protocol && window.location.host === url.host)
            url.protocol = window.location.protocol;
        offDomain = window.location.host !== url.host;
    } catch (e) {
        // Ignored on purpose
    }
    const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
        title: link.title,
        target: link.target,
        className: 'nav-item',
    };
    if (offDomain) {
        linkProps.rel = 'noreferrer';
    }

    if (isMobile) {
        return (
            <>
                <div className="grid-horizontal-mobile-in-nav"></div>
                <Link
                    className="main-menu-item-mobile card-yellow-focus-mobile px-0"
                    to={url.href}
                    onClick={() => {
                        isMenuOpenMobile(true);
                    }}
                >
                    <Typography typographyType="bodySans02" {...linkProps}>
                        {link.text}
                    </Typography>
                    <Icon
                        color="rgba(53, 71, 84, 1)"
                        icon={link.target === '_blank' ? OpenExternalIcon : ArrowRightIcon}
                        iconSize="small"
                    />
                </Link>
            </>
        );
    }

    return (
        <li className="row submenu-link-list">
            <Link
                className="col-12 p-0 card-yellow-focus submenu-link main-menu-item"
                to={url.href}
                onClick={() => {
                    isMenuOpen(true);
                }}
            >
                <Typography
                    typographyType="bodySans03"
                    {...linkProps}
                    className="py-3 mx-3 pe-2 me-5 btn-link"
                    as="div"
                >
                    <span>{link.text}</span>
                </Typography>
                {!ctx.isServerSideRendering() && (
                    <Icon
                        color="rgba(53, 71, 84, 1)"
                        className="icon-right"
                        icon={link.target === '_blank' ? OpenExternalIcon : ArrowRightIcon}
                        iconSize="medium"
                    />
                )}
            </Link>
        </li>
    );
};

export default LinkListLink;
