import { TextInput, SearchIcon, Button, Search, Label } from '@norges-domstoler/dds-components';
import { getLocalizationText, General } from '../../../../utils/getLocalizationText';
import { useEpiserver } from '@episerver/spa-core';
import { useNavigate } from 'react-router';
import store from '../../../../store';
import React from 'react';

type SearchBoxHeaderProps = {
    isMobile: boolean;
    setIsOpenSearch: any;
    isOpenSearch: boolean;
    searchPath: string;
    language: string;
    path: string;
    searchFieldRef: React.MutableRefObject<undefined>;
};

export const SearchBoxHeader: React.FunctionComponent<SearchBoxHeaderProps> = (props) => {
    const navigate = useNavigate();
    const ctx = useEpiserver();

    const handleButtonPress = (e: React.MouseEvent<HTMLButtonElement>) => {
        const searchUrl = getSearchUrl();
        if (searchUrl) {
            props.setIsOpenSearch(false);
            if (props.isMobile) {
                store.dispatch({ type: 'isMenuOpen', payload: false });
            }
            redirectToSearchPage(searchUrl);
        } else {
            e.preventDefault();
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key == 'Enter') {
            const searchUrl = getSearchUrl();
            if (searchUrl) {
                props.setIsOpenSearch(false);
                if (props.isMobile) {
                    store.dispatch({ type: 'isMenuOpen', payload: false });
                }
                redirectToSearchPage(searchUrl);
            }
        }
    };

    function redirectToSearchPage(newSearchUrl: string) {
        navigate(ctx.getSpaRoute(newSearchUrl));
        const currentPath = props.path.split('?');
        const searchPage = ctx.getSpaRoute(newSearchUrl).split('?');
        if (currentPath.length > 0 && searchPage.length > 0 && currentPath[0] == searchPage[0]) {
            window.location.reload();
        }
    }

    const getSearchUrl = () => {
        if (
            props.searchFieldRef &&
            props.searchFieldRef.current &&
            props.searchFieldRef.current['value'] &&
            props.searchPath != null &&
            props.searchPath != '#'
        ) {
            let searchUrl = new URL(props.searchPath).pathname;
            searchUrl += '?search=' + props.searchFieldRef.current['value'];
            return searchUrl;
        }
        return null;
    };

    return (
        <>
            {props.isMobile ? (
                <form
                    role="search"
                    className="row col-12 col-lg-6"
                    aria-label={getLocalizationText(props.language, [General, 'Search'])}
                >
                    <Label className="startpage-searchbar-header" htmlFor="mobile-header-search-box">
                        {getLocalizationText(props.language, [General, 'SearchInputLabel'])}
                    </Label>
                    <div className="col-9 col-lg-5">
                        <TextInput
                            id="mobile-header-search-box"
                            onKeyPress={handleKeyPress}
                            type="search"
                            ref={props.searchFieldRef}
                            className="search-input-field"
                            autoFocus
                        />
                    </div>
                    <div className="col-3 col-lg-1">
                        <Button
                            size="large"
                            type="submit"
                            icon={SearchIcon}
                            appearance="rounded"
                            className="search-button"
                            onClick={handleButtonPress}
                            aria-label={getLocalizationText(props.language, [General, 'Search'])}
                        />
                    </div>
                </form>
            ) : (
                <form
                    role="search"
                    className="row col-12"
                    onSubmit={(e) => e.preventDefault()}
                    onFocus={() => props.setIsOpenSearch(true)}
                >
                    <Label className="startpage-searchbar-header m-0" htmlFor="header-search-box">
                        {getLocalizationText(props.language, ['Header', 'SearchQuestion'])}
                    </Label>
                    <div className="col-9 mt-3">
                        {ctx.isServerSideRendering() ? (
                            <TextInput
                                id="header-search-box"
                                type="search"
                                onKeyPress={handleKeyPress}
                                ref={props.searchFieldRef}
                                className="search-input-field"
                                onFocus={() => props.setIsOpenSearch(true)}
                            />
                        ) : (
                            <Search
                                id="header-search-box"
                                tabIndex={props.isOpenSearch ? 0 : -1}
                                type="search"
                                onKeyPress={handleKeyPress}
                                ref={props.searchFieldRef}
                                className="search-input-field"
                                onFocus={() => props.setIsOpenSearch(true)}
                            />
                        )}
                    </div>
                    <div className="col-2 mt-3">
                        <Button
                            size="large"
                            type="submit"
                            icon={SearchIcon}
                            appearance="rounded"
                            className="search-button"
                            onClick={handleButtonPress}
                            aria-label={getLocalizationText(props.language, [General, 'Search'])}
                            htmlProps={{ tabIndex: props.isOpenSearch ? 0 : -1 }}
                            onFocus={() => props.setIsOpenSearch(true)}
                        />
                    </div>
                </form>
            )}
        </>
    );
};

export default SearchBoxHeader;
