import AboutTheWebsiteBlockData from 'app/Models/Content/AboutTheWebsiteBlockData';
import { getLocalizationText } from '../../../utils/getLocalizationText';
import FooterLink from './FooterLink';
import './FooterTilsynsutvalget.scss';
import React from 'react';

export type FooterProps = {
    aboutWebsite: AboutTheWebsiteBlockData;
    language: string;
};

export const FooterTilsynsutvalget: React.FunctionComponent<FooterProps> = ({ aboutWebsite, language }) => {
    return (
        <>
            <div className="footer-links-container">
                <div className="footer-links">
                    <h2 className="visually-hidden">{getLocalizationText(language, ['Footer', 'Label'])}</h2>
                    <FooterLink url={aboutWebsite?.availabilityUrl?.value} withChevron={false} />
                    <FooterLink url={aboutWebsite?.privacyUrl?.value} withChevron={false} />
                </div>
            </div>
        </>
    );
};
export default FooterTilsynsutvalget;
