import AboutTheWebsiteBlock from '../BlockAsProperties/AboutTheWebsiteBlock';
import {
    Typography,
    Icon,
    Link,
    FacebookIcon,
    XIcon,
    InstagramIcon,
    LinkedInIcon,
    FlickrIcon,
} from '@norges-domstoler/dds-components';
import OurServicesBlock from '../BlockAsProperties/OurServicesBlock';
import { getLocalizationText } from '../../../utils/getLocalizationText';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import ContactBlock from '../BlockAsProperties/ContactBlock';
import { useEpiserver } from '@episerver/spa-core';
import React from 'react';
import './Footers.scss';

export type FooterProps = {
    settings: LayoutSettings;
};

export const FooterDomstolno: React.FunctionComponent<FooterProps> = ({ settings }) => {
    const context = useEpiserver();

    const language = settings?.language?.name;

    return (
        <>
            <div className="col-12 col-lg pb-0 pb-lg-5 text-center footer-image-container">
                <img
                    className="footer-image"
                    alt={getLocalizationText(language, ['Footer', 'Logo'])}
                    width="188"
                    height="100"
                    src={'/Static/logo/hovedlogo_negativ_' + (language ? language : 'no') + '.svg'}
                />
            </div>

            {settings?.contactBlock && <ContactBlock data={settings.contactBlock} className="col-12 col-lg px-1" />}

            {settings?.ourServicesBlock && (
                <OurServicesBlock data={settings.ourServicesBlock} className="col-12 col-lg px-1" />
            )}

            {settings?.aboutTheWebsiteBlock && (
                <AboutTheWebsiteBlock data={settings.aboutTheWebsiteBlock} className="col-12 col-lg px-1" />
            )}

            <div className="col-12 col-lg px-0">
                <div className="row url-list mt-0 mt-lg-5 py-5 text-center text-lg-start">
                    <div className="col-12">
                        <Typography typographyType="headingSans03" className="footer-heading" as="h2">
                            {settings?.socialMediaBlock?.header?.value}
                        </Typography>
                    </div>
                    {!context.isServerSideRendering() && (
                        <>
                            {settings?.socialMediaBlock?.facebook?.value && (
                                <div className="col-12 mt-3">
                                    <Link
                                        typographyType="bodySans03"
                                        className="social-media-container footer-url dark-focus-internal-link"
                                        href={settings.socialMediaBlock.facebook.value}
                                        withMargins
                                    >
                                        <Icon icon={FacebookIcon} />
                                        <p className="social-media-text">Facebook</p>
                                    </Link>
                                </div>
                            )}
                            {settings?.socialMediaBlock?.twitter?.value && (
                                <div className="col-12 mt-3">
                                    <Link
                                        typographyType="bodySans03"
                                        className="social-media-container footer-url dark-focus-internal-link"
                                        href={settings.socialMediaBlock.twitter.value}
                                        withMargins
                                    >
                                        <Icon icon={XIcon} />
                                        <p className="social-media-text">X</p>
                                    </Link>
                                </div>
                            )}
                            {settings?.socialMediaBlock?.instagram?.value && (
                                <div className="col-12 mt-3">
                                    <Link
                                        typographyType="bodySans03"
                                        className="social-media-container footer-url dark-focus-internal-link"
                                        href={settings.socialMediaBlock.instagram.value}
                                        withMargins
                                    >
                                        <Icon icon={InstagramIcon} />
                                        <p className="social-media-text">Instagram</p>
                                    </Link>
                                </div>
                            )}
                            {settings?.socialMediaBlock?.linkedIn?.value && (
                                <div className="col-12 mt-3">
                                    <Link
                                        typographyType="bodySans03"
                                        className="footer-url linkedin dark-focus-internal-link"
                                        href={settings.socialMediaBlock.linkedIn.value}
                                        withMargins
                                    >
                                        <p className="linkedin-text">Linked</p>
                                        <Icon className="social-media-svg linkedin-svg" icon={LinkedInIcon} />
                                    </Link>
                                </div>
                            )}
                            {settings?.socialMediaBlock?.flickr?.value && (
                                <div className="col-12 mt-3">
                                    <Link
                                        typographyType="bodySans03"
                                        className="social-media-container footer-url dark-focus-internal-link"
                                        href={settings.socialMediaBlock.flickr.value}
                                        withMargins
                                    >
                                        <Icon icon={FlickrIcon} />
                                        <p className="social-media-text">Flickr</p>
                                    </Link>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default FooterDomstolno;
