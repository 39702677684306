import AboutTheWebsiteBlockData from 'app/Models/Content/AboutTheWebsiteBlockData';
import { minWordLengthFooterText } from '../../../Models/Constants/SiteSettings';
import { Typography } from '@norges-domstoler/dds-components';
import { HyphenateTitleElement } from '../../Shared/renderTitle';
import FooterLink from '../Footer/FooterLink';
import React, { FunctionComponent } from 'react';

export const AboutTheWebsiteBlock: FunctionComponent<{ data?: AboutTheWebsiteBlockData } & { className?: string }> = (
    props
) => {
    if (props.data?.availabilityUrl?.value === null && props.data?.privacyUrl?.value === null) {
        return <div className={'col-12 col-lg px-0'} />;
    }

    return (
        <div className={props.className}>
            <div className="row url-list mt-0 mt-lg-5 pt-5 text-center text-lg-start">
                <div className="col-12">
                    <Typography typographyType="headingSans03" className="footer-heading" as="h2">
                        <HyphenateTitleElement
                            minWordLength={minWordLengthFooterText}
                            title={props.data.header.value}
                        />
                    </Typography>
                </div>
                <FooterLink url={props.data?.availabilityUrl?.value} />
                <FooterLink url={props.data?.privacyUrl?.value} />
            </div>
        </div>
    );
};

export default AboutTheWebsiteBlock;
