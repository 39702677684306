import { getLocalizationText } from '../../../utils/getLocalizationText';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import { SiteType } from '../../../Models/Enums/SiteType';
import FooterDomstolno from './FooterDomstolno';
import FooterTU from './FooterTilsynsutvalget';
import React from 'react';
import './Footers.scss';

export type FooterProps = {
    settings: LayoutSettings;
};

export const Footer: React.FunctionComponent<FooterProps> = ({ settings }) => {
    const siteType = settings?.siteType?.value;

    return (
        <footer className="footer-max-width-section">
            <nav
                id="footer-nav"
                className="row footer-max-width container"
                aria-label={getLocalizationText(settings?.language?.name, ['Footer', 'Label'])}
            >
                {siteType === SiteType.Tilsynsutvalget ? (
                    <FooterTU aboutWebsite={settings.aboutTheWebsiteBlock} language={settings.language?.name} />
                ) : (
                    <FooterDomstolno settings={settings} />
                )}
            </nav>
        </footer>
    );
};

export default Footer;
