import { useIContentRepository, Taxonomy, State, useEpiserver } from '@episerver/spa-core';
import { Icon, Typography, CheckIcon } from '@norges-domstoler/dds-components';
import Website from '@episerver/spa-core/dist/Models/Website';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../Header/SearchBoxHeader.scss';
import { connect } from 'react-redux';
import './LanguageSelector.scss';
import '../Header/Header.scss';
import axios from 'axios';
import {
    norwegian_language_tag_short,
    samegiella_language_tag_short,
    english_language_tag_short,
} from '../../../../Models/Constants/LanguageTypes';

export type LanguageSelectorProps = Partial<State.CmsState> & {
    dispatch?: (action: State.CmsStateAction) => void;
    languageNames: { [id: string]: string };
    useEnglish: boolean;
    useSamisk: boolean;
    isOpenLanguageSelector: boolean;
    setIsOpenLanguageSelector: any;
    layoutSettingsLanguage: string;
    isMenuOpen?: any;
    onFocus?: any;
    path?: string;
    languageFieldRef?: React.MutableRefObject<undefined>;
    useLanguageSelector: boolean;
    isMobile: boolean;
};

export const DefaultLanguageSelector: React.FunctionComponent<LanguageSelectorProps> = (props) => {
    const repo = useIContentRepository();
    const navigate = useNavigate();
    const ctx = useEpiserver();

    const [languages, setLanguages] = useState<Taxonomy.LanguageList>([]);

    const createLanguageList = () => {
        const langList = [];
        const no = languages.filter((l) => l.name === norwegian_language_tag_short)[0];
        const sa = languages.filter((l) => l.name === samegiella_language_tag_short && props.useSamisk)[0];
        const en = languages.filter((l) => l.name === english_language_tag_short && props.useEnglish)[0];
        if (no) {
            langList.push(no);
        }
        if (sa) {
            langList.push(sa);
        }
        if (en) {
            langList.push(en);
        }
        return langList;
    };

    const LanguageList = createLanguageList();

    // Select language handler
    const selectLanguage = (l: Taxonomy.Language) => {
        if (props.dispatch) props.dispatch({ type: 'OptiContentCloud/SetState', currentLanguage: l.name });

        if (props.path) {
            repo.getByRoute(props.path).then((currentContent) => {
                if (currentContent.existingLanguages && currentContent.existingLanguages.length > 0) {
                    const currentContentInNewLanguageList = currentContent.existingLanguages.filter(
                        (lang) => lang.name === l.name
                    );
                    if (currentContentInNewLanguageList && currentContentInNewLanguageList.length > 0) {
                        const currentContentInNewLanguageUrl = new URL(currentContentInNewLanguageList[0].link);
                        navigate(ctx.getSpaRoute(currentContentInNewLanguageUrl.pathname));
                    } else {
                        // do this to set show norwegian page if page has not avaiable language
                        const norwegianContent = currentContent.existingLanguages.filter((lang) => lang.name === 'no');
                        if (norwegianContent && norwegianContent.length > 0) {
                            const norwegianContentLanguageUrl = new URL(norwegianContent[0].link);
                            navigate(ctx.getSpaRoute(norwegianContentLanguageUrl.pathname));
                        }
                    }
                }
            });
        } else if (l.urlSegment) navigate(ctx.getSpaRoute(l.urlSegment));

        props.setIsOpenLanguageSelector(false);
        props?.isMenuOpen && props.isMenuOpen(false);
    };

    // Load the languages
    useEffect(() => {
        let isCancelled: boolean = false;

        repo.getCurrentWebsite().then((x) => {
            if (isCancelled) return;
            if (!x) {
                axios.get('/api/episerver/v3.0/site').then((response) => {
                    setLanguages((response.data[0] as Website).languages);
                });
            } else {
                setLanguages(x.languages);
            }
        });
        return () => {
            isCancelled = true;
        };
    }, []);

    // Only render the selector if we have 1 or more languages
    if (languages.length <= 1 || !props.useLanguageSelector) return null;

    return (
        <div className="language-selection">
            {LanguageList.map((x, index) => (
                <div className="col-12 row" key={`lang-${x.name}`}>
                    {x.name === props.currentLanguage && (
                        <div className="col-5 text-end pe-0 language-icon-wrapper">
                            <Icon icon={CheckIcon} />
                        </div>
                    )}
                    <div className="col-7 ms-auto my-3 text-start">
                        <Typography
                            ref={index == 0 ? props.languageFieldRef : null}
                            typographyType={props.isMobile ? 'bodySans03' : 'headingSans03'}
                            as="button"
                            htmlProps={{
                                tabIndex: props.isOpenLanguageSelector ? 0 : -1,
                                onClick: () => selectLanguage(x),
                                lang: x?.urlSegment,
                            }}
                            key={`lang-${x.name}`}
                            className={
                                'link-card-focus ' +
                                (x.name === props.currentLanguage ? 'active' : '') +
                                (props.isMobile ? ' mobile' : '')
                            }
                        >
                            {props.languageNames[x.name]}
                        </Typography>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const ConnectedLanguageSelector = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(
    DefaultLanguageSelector
);

export const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = (props) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <DefaultLanguageSelector {...props} />;
    return <ConnectedLanguageSelector {...props} />;
};

export default LanguageSelector;
