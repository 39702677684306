import { getLinkTitleFromLinkProperty, getLinkUrlFromLinkProperty } from '../../Shared/Utils';
import { minWordLengthFooterText } from '../../../Models/Constants/SiteSettings';
import { HyphenateTitleElement } from '../../Shared/renderTitle';
import { ChevronRightIcon, Icon } from '@norges-domstoler/dds-components';
import { ContentDelivery } from '@episerver/spa-core';
import React from 'react';
import Link from '../../Shared/Link/link';

export type FooterLinkProps = {
    url: ContentDelivery.LinkProperty;
    withChevron?: boolean;
    minWordLenght?: number;
};

export const FooterLink: React.FunctionComponent<FooterLinkProps> = ({
    url,
    withChevron = true,
    minWordLenght = minWordLengthFooterText,
}) => {
    if (!url) return;

    return (
        <div className="col-12 mt-3">
            <Link className="dark-focus-internal-link footer-url" href={getLinkUrlFromLinkProperty(url)} withMargins>
                {withChevron && <Icon icon={ChevronRightIcon} className="footer-chevron" />}
                <p>
                    <HyphenateTitleElement minWordLength={minWordLenght} title={getLinkTitleFromLinkProperty(url)} />
                </p>
            </Link>
        </div>
    );
};
export default FooterLink;
